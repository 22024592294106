/* eslint-disable complexity */
import { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { stringify } from 'qs';
import type { Geometry, Point } from 'geojson';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Tag, useToast } from '@chakra-ui/react';
import type { LmmImage, LmmImagesFilter, LmmRoad, LmmRoadAsset, LmmSection, LmmSegment } from '../shared/entity.js';
import { useTitle } from '../shared/hook/useTitle.js';
import { AssetType, defaultPciRange, PciCategory } from '../shared/const.js';
import { capitalizeEachWord } from '../utils/stringUtils.js';
import { ShowRoadAssetsFilter } from '../interface.js';
import { GetLmmRoads, GetTenantMap } from '../query/mapqueries.js';
import { useMapRecenter } from '../shared/hook/useMapRecenter.js';
import TopNavBar from '../shared/component/TopNavBar.js';
import { GlobalContext } from '../context/GlobalContext.js';
import DISPATCH_ACTIONS from '../context/actions.js';
import { APP_COLORS, PCI_COLORS } from '../utils/constants.js';
import {
  getStartAndEndPointsFromLineString,
  isCoordinateOnLineString,
  reorderLatLngByPath,
} from '../utils/mapUtils.js';
import FullScreenLoader from '../shared/component/FullScreenLoader.js';
import {
  addFeatureGroup,
  buildFeatures,
  buildSection,
  convertToGoogleMapsGeometry,
  extractCoordinates,
  getCategoryKeyFromPci,
  getClosestPointOnPath,
  getColorFromPci,
  getSegmentMarkers,
  isSegmentImage,
  isSegmentMarker,
  isBetweenTwoPointsAlongAPath,
  removeFeatureGroup,
} from './mapHelper.js';
import { buildInletMarker, buildLmmImageMarker, buildStopSignMarker } from './mapMarkers.js';
import { MapLmmImageModal } from './MapLmmImageModal.js';
import { MapToolbar } from './MapToolbar.js';
import { MapRoadAssetModal } from './MapRoadAssetModal.js';
import { RoadInfoModal } from './modals/RoadInfoModal.js';
import CustomRenderer from './component/CustomRenderer.js';

export function MapPage() {
  const { state, dispatch } = useContext(GlobalContext);
  const mapElRef = useRef<HTMLDivElement>();
  const mapRef = useRef<google.maps.Map>();
  const milePostLineRef = useRef<google.maps.Polyline | null>(null);
  const startMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  const stopMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  const [selectedLmmImageId, setSelectedLmmImageId] = useState<string>();
  const lmmRoadsRef = useRef<LmmRoad[]>([]);
  const roadSignsRef = useRef<LmmRoadAsset[]>([]);
  const inletsRef = useRef<LmmRoadAsset[]>([]);
  const guardrailsRef = useRef<LmmRoadAsset[]>([]);
  const sidewalksRef = useRef<LmmRoadAsset[]>([]);
  const previouslySelectedLmmImageRef = useRef<LmmImage>();
  const previouslySelectedLmmRoadAssetRef = useRef<LmmRoadAsset>();
  const [selectedLmmSection, setSelectedLmmSection] = useState<LmmSection>();
  const [selectedRoadAsset, setSelectedRoadAsset] = useState<LmmRoadAsset>();
  const sectionsRef = useRef<LmmSection[]>([]);
  // const roadsRef = useRef<LmmRoad[]>();
  const [selectedRoad, setSelectedRoad] = useState<LmmRoad>();
  const preloadedSegmentImages = useRef<{ [segmentId: string]: true }>({});
  const prevSelectedLmmSection = useRef<LmmSection>();
  const [lmmImagesFilter, setLmmImagesFilter] = useState<LmmImagesFilter>({ pci: [0, 100] });
  const lmmImagesFilterStr = useMemo(() => stringify(lmmImagesFilter), [lmmImagesFilter]);
  const markersRef = useRef<readonly google.maps.marker.AdvancedMarkerElement[]>([]);
  const roadAssetMakersRef = useRef<readonly google.maps.marker.AdvancedMarkerElement[]>([]);
  const hasAutoFitBoundsRanRef = useRef<boolean>();
  const [selectedSegment, setSelectedSegment] = useState<LmmSegment>();
  const [sectionIsSelected, setSectionIsSelected] = useState<boolean>();
  const [isLoadingSections, setIsLoadingSections] = useState<boolean>();
  const featuresRemovedRef = useRef<google.maps.Data.Feature[]>([]);
  const forceUseImageCoordinateForCentering = useRef<boolean>(false);
  const createJobOpenedRef = useRef<boolean | LmmRoad>(false);
  const [assetFilterisActive, setAssetsFilterisActive] = useState<boolean>();
  const toast = useToast();
  const [mapIsSet, setMapIsSet] = useState<boolean>(false);
  const [rerun, setReRun] = useState<boolean>(false);
  const { data: map = {} } = GetTenantMap();
  const { data: roadsRef = [] } = GetLmmRoads();
  const [displayedFeatures, setDisplayedFeatures] = useState({});
  const [endAnction, setEndAction] = useState('');
  const [clickedCoordinate, setClickedCoordinate] = useState<google.maps.LatLng | null>(null);
  const [showAssets, setShowAssets] = useState({ roadSigns: true, guardrails: true, sidewalks: true, inlets: true });
  const [showFilter, setShowFilter] = useState<ShowRoadAssetsFilter>({
    roadSign: true,
    guardrail: true,
    sidewalk: true,
    inlet: true,
    current: 'all',
  });
  const clustererRef = useRef<any>(null); // Reference to MarkerClusterer instance
  const markersByRoad = useRef<{ [key: string]: google.maps.marker.AdvancedMarkerElement[] }>({});
  const sectionsByRoad = useRef<{ [key: string]: LmmSection[] }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   // Build a dictionary of roadId -> markers
  //   markersByRoad.current = {};
  //   markersRef.current.forEach((marker) => {
  //     const roadId = marker.dataset.roadId;
  //     if (roadId) {
  //       if (!markersByRoad.current[roadId]) {
  //         markersByRoad.current[roadId] = [];
  //       }
  //       markersByRoad.current[roadId].push(marker);
  //     }
  //   });
  //   if (markersRef.current.length) {
  //     setAppIsReady(true);
  //   }
  // }, [markersRef.current]);

  useEffect(() => {
    sectionsByRoad.current = {};
    sectionsRef.current.forEach((section) => {
      if (!sectionsByRoad.current[section.roadId]) {
        sectionsByRoad.current[section.roadId] = [];
      }
      sectionsByRoad.current[section.roadId].push(section);
    });
    if (sectionsRef.current.length) {
      setIsLoading(false);
    }
  }, [sectionsRef.current]);

  const onToggleShowRoadAsset = (type?: AssetType) => {
    const copyFilter = { ...showFilter };
    if (type) {
      copyFilter[type] = !copyFilter[type];
      copyFilter.current = type;
    } else {
      copyFilter.guardrail = true;
      copyFilter.sidewalk = true;
      copyFilter.roadSign = true;
      copyFilter.inlet = true;
      copyFilter.current = 'all';
      setAssetsFilterisActive(false);
      // setEndAction('reset');
    }
    setShowFilter(copyFilter);
  };
  useEffect(() => {
    dispatch({ type: DISPATCH_ACTIONS.SET_IS_CREATING_JOB, payload: false });
  }, [dispatch]);

  const segments = useMemo(() => {
    if (selectedSegment) {
      const sections = sectionsRef.current.filter((x) => x.roadId == selectedLmmSection?.roadId);
      return sections;
    } else return [map];
  }, [map, selectedLmmSection, selectedSegment]);

  const selectedLmmImage = useMemo<LmmImage>(
    () => (selectedLmmImageId ? state.lmmImages.find((lmmImage) => lmmImage.id === selectedLmmImageId) : undefined),
    [selectedLmmImageId, state.lmmImages]
  );

  useTitle(map?.name);

  const recenter = useMapRecenter({ mapRef, markersRef });

  useEffect(() => {
    if (state.lmmRoads?.length) {
      lmmRoadsRef.current = state.lmmRoads;
    }
  }, [state.lmmRoads]);

  const autoCenter = useCallback(
    (
      reference:
        | Geometry
        | google.maps.LatLng
        | google.maps.Data.Geometry
        | google.maps.Data
        | readonly google.maps.marker.AdvancedMarkerElement[]
    ) => {
      if (hasAutoFitBoundsRanRef.current) {
        return;
      }
      hasAutoFitBoundsRanRef.current = true;
      recenter(reference);
    },
    [recenter]
  );

  const revertMarkerView = (clearPreviousMarkerState?: boolean) => {
    markersRef.current.forEach((marker) => {
      if (previouslySelectedLmmImageRef.current && marker.id === previouslySelectedLmmImageRef.current.id) {
        marker.content = buildLmmImageMarker(previouslySelectedLmmImageRef.current);
        marker.map = mapRef.current;
        if (clearPreviousMarkerState) {
          previouslySelectedLmmImageRef.current = undefined;
          forceUseImageCoordinateForCentering.current = false;
        }
      }
    });
  };

  const revertRoadAssetMarkerView = () => {
    roadAssetMakersRef.current.forEach((marker) => {
      if (previouslySelectedLmmRoadAssetRef.current && marker.id === previouslySelectedLmmRoadAssetRef.current.id) {
        marker.content =
          marker.dataset.type == AssetType.roadSign
            ? buildStopSignMarker(previouslySelectedLmmRoadAssetRef.current)
            : buildInletMarker(previouslySelectedLmmRoadAssetRef.current);
        marker.map = mapRef.current;
        previouslySelectedLmmRoadAssetRef.current = undefined;
      }
    });
  };

  useEffect(() => {
    const initializeMap = async () => {
      const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;

      mapRef.current = new Map(mapElRef.current, {
        mapId: 'map',
        mapTypeControlOptions: {
          position: google.maps.ControlPosition?.BOTTOM_LEFT,
        },
        noClear: true,
        fullscreenControl: false,
      });

      // Add a click event listener to the map
      mapRef.current.addListener('click', (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
          setClickedCoordinate(event.latLng);
        }
      });
      setMapIsSet(true);
    };
    try {
      initializeMap();
    } catch (error) {
      initializeMap();
    }
  }, []);

  useEffect(() => {
    if (mapIsSet) {
      mapRef.current.data.addListener('click', async ({ feature }: { feature: google.maps.Data.Feature }) => {
        // onFilterMap(defaultPciRange);
        setEndAction('reset');
        // setTimeout(() => {
        // setEndAction('reset');
        const section: LmmSection = buildSection(feature);
        const sectionRoadId = section.roadId;
        setIsLoading(true);
        // let relevantMarkers = state.markersByRoad[sectionRoadId];
        let relevantMarkers = markersByRoad.current[sectionRoadId];
        console.log('relevantMarkers', relevantMarkers);
        // console.log('state.markersByRoad', state.markersByRoad);
        if (!relevantMarkers && sectionRoadId) {
          const response = await fetch(`/api/roads/images/${sectionRoadId}`);
          if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
          }
          const result = await response.json();
          dispatch({ type: DISPATCH_ACTIONS.SET_LMMIMAGES, payload: result.data });
          if (!selectedLmmSection) {
            dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: result.data });
          }
          // if (lmmImagesFilter.pci[0] == 0 && lmmImagesFilter.pci[1] == 100 && newLmmImages) {
          //   localStorage.setItem('totalImageCount', `${newLmmImages.length}`);
          // }
          const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
          const allMarkers = result.data.map((img: LmmImage) => {
            const marker = new AdvancedMarkerElement({
              position: {
                lat: img.position.coordinates[0],
                lng: img.position.coordinates[1],
              },

              content: buildLmmImageMarker(img, selectedLmmImageId),
            });

            marker.id = img.id;
            marker.dataset.roadId = img.roadId;
            marker.dataset.sectionId = img.sectionId;
            marker.dataset.pci = `${img.pci}`;
            marker.addListener('click', () => {
              // if (!state.isCreatingJob) {
              setSelectedLmmImageId(img.id);
              const road = roadsRef.find((x) => x.id == img.roadId);
              setSelectedRoad(road);
              // }
            });

            return marker;
          });
          if (sectionRoadId && allMarkers) {
            // dispatch({
            //   type: DISPATCH_ACTIONS.SET_MARKERS_BY_ROAD,
            //   payload: { markers: allMarkers, roadId: sectionRoadId },
            // });
            markersByRoad.current[sectionRoadId] = allMarkers;
          }
          markersRef.current = [...markersRef.current, ...allMarkers];
          // if (!selectedLmmImageId) {
          //   markersRef.current = allMarkers;
          // }
          relevantMarkers = allMarkers || [];
        }

        setIsLoading(false);
        if (!relevantMarkers.length) {
          toast({ title: 'No data found for this road', status: 'info', duration: 3000 });
          return;
        }
        setTimeout(() => {
          markersRef.current.forEach((marker) => {
            if (isSegmentMarker(marker, section)) {
              marker.map = mapRef.current;
            } else {
              marker.map = null;
            }
          });
        }, 300);
        // const allRelatedSections = sectionsRef.current.filter((x) => x.roadId == section.roadId);
        // const featuresToRemove: google.maps.Data.Feature[] = [];
        // for (const section of allRelatedSections) {
        //   const feature = mapRef.current.data.getFeatureById(section.id);
        //   featuresToRemove.push(feature);
        //   mapRef.current.data.remove(feature);
        // }

        const allRelatedSections = sectionsByRoad.current[sectionRoadId] || [];
        const featuresToRemove = new Set<google.maps.Data.Feature>();
        allRelatedSections.forEach((sec) => {
          const feature = mapRef.current.data.getFeatureById(sec.id);
          if (feature) {
            featuresToRemove.add(feature);
          }
        });

        // Remove all features in one batch
        mapRef.current.data.forEach((feature) => {
          if (featuresToRemove.has(feature)) {
            mapRef.current.data.remove(feature);
          }
        });

        if (featuresRemovedRef.current?.length) {
          const sectionInRemoval: LmmSection = buildSection(featuresRemovedRef.current[0]);
          if (sectionInRemoval.roadId !== section.roadId) {
            for (const feature of featuresRemovedRef.current) {
              mapRef.current.data.add(feature);
            }
          }
        }

        featuresRemovedRef.current = Array.from(featuresToRemove);
        setSelectedLmmSection(section);
        setSelectedRoad(lmmRoadsRef.current.find((x) => x.id === sectionRoadId) || null);
        // }, 300);
      });
    }
  }, [mapIsSet, rerun]);

  //   useEffect(() => {
  //     if (!mapIsSet || !mapRef.current) return;

  //     const onClickHandler = ({ feature }: { feature: google.maps.Data.Feature }) => {
  //       setEndAction('reset');

  //       // requestAnimationFrame(() => {
  //       const section: LmmSection = buildSection(feature);

  //       // Show only relevant markers
  //       const sectionRoadId = section.roadId;

  //       const relevantMarkers = markersByRoad.current.get(section.id) || [];
  //       requestAnimationFrame(() => {
  //         relevantMarkers.forEach((marker) => {
  //           marker.map = mapRef.current;
  //         });
  //       });
  //       // Batch remove features instead of iterating and removing one-by-one
  //       const allRelatedSections = sectionsByRoad.current.get(section.roadId) || [];
  //       const featuresToRemove = new Set<google.maps.Data.Feature>();
  // allRelatedSections.forEach((sec) => {
  //   const feature = mapRef.current.data.getFeatureById(sec.id);
  //   if (feature) {
  //     featuresToRemove.add(feature);
  //   }
  // });

  // // Remove all features in one batch
  // mapRef.current.data.forEach((feature) => {
  //   if (featuresToRemove.has(feature)) {
  //     mapRef.current.data.remove(feature);
  //   }
  // });

  //       // const featuresToRemove = new Set(
  //       //   allRelatedSections.map((s) => mapRef.current?.data.getFeatureById(s.id)).filter((f): f is google.maps.Data.Feature => f !== null)
  //       // );
  //       // const featuresToRemove: google.maps.Data.Feature[] = allRelatedSections
  //       //   .map((s) => mapRef.current.data.getFeatureById(s.id))
  //       //   .filter((f): f is google.maps.Data.Feature => f !== null);

  //       // if (featuresToRemove.length > 0) {
  //       //   mapRef.current.data.forEach((f) => {
  //       //     if (featuresToRemove.includes(f)) {
  //       //       mapRef.current.data.remove(f);
  //       //     }
  //       //   });
  //       // }

  //       // Restore previous removed features if necessary
  //       if (featuresRemovedRef.current.length) {
  //         const prevSection: LmmSection = buildSection(featuresRemovedRef.current[0]);
  //         if ((prevSection.roadId as LmmSection) !== sectionRoadId) {
  //           featuresRemovedRef.current.forEach((feature) => mapRef.current.data.add(feature));
  //         }
  //       }

  //       featuresRemovedRef.current = featuresToRemove;
  //       setSelectedLmmSection(section);

  //       // Find the road efficiently
  //       setSelectedRoad(lmmRoadsRef.current.find((x) => x.id === sectionRoadId) || null);
  //       // });
  //     };

  //     const listener = mapRef.current.data.addListener('click', onClickHandler);

  //     return () => {
  //       google.maps.event.removeListener(listener);
  //     };
  //   }, [mapIsSet, rerun]);

  useEffect(() => {
    const checkFilterToShow = async () => {
      const runForRoadSign = roadSignsRef.current && (showFilter.current == 'all' || showFilter.current == 'roadSign');
      const runForGuardrail =
        guardrailsRef.current && (showFilter.current == 'all' || showFilter.current == 'guardrail');
      const runForSidewalk = sidewalksRef.current && (showFilter.current == 'all' || showFilter.current == 'sidewalk');
      const runForInlet = inletsRef.current && (showFilter.current == 'all' || showFilter.current == 'inlet');
      if (showFilter.roadSign && runForRoadSign) {
        await showRoadSigns(AssetType.roadSign);
      } else if (runForRoadSign) {
        await hideRoadSigns(AssetType.roadSign);
      }
      if (showFilter.inlet && runForInlet) {
        await showRoadSigns(AssetType.inlet);
      } else if (runForInlet) {
        await hideRoadSigns(AssetType.inlet);
      }
      if (showFilter.guardrail && runForGuardrail && guardrailsRef.current.length) {
        const guardRailsCords = guardrailsRef.current.map((x) => x.geometry);
        addFeatureGroup(
          mapRef.current,
          guardrailsRef.current,
          guardRailsCords,
          AssetType.guardrail,
          ['#F4A460', '#000000'],
          setDisplayedFeatures,
          onClickAssetlineCallback
        );
      } else if (runForGuardrail) {
        removeFeatureGroup(AssetType.guardrail, setDisplayedFeatures);
      }
      if (showFilter.sidewalk && runForSidewalk && sidewalksRef.current.length) {
        const sidewalksCords = sidewalksRef.current.map((x) => x.geometry);
        addFeatureGroup(
          mapRef.current,
          sidewalksRef.current,
          sidewalksCords,
          AssetType.sidewalk,
          ['#3888FF', '#ffffff'],
          setDisplayedFeatures,
          onClickAssetlineCallback
        );
      } else if (runForSidewalk) {
        removeFeatureGroup(AssetType.sidewalk, setDisplayedFeatures);
      }
    };
    if (mapIsSet) {
      checkFilterToShow();
    }
  }, [mapIsSet, showFilter, rerun]);

  const updateImagesInView = useCallback(
    (roadId?: string) => {
      if (!roadId) {
        dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: state.lmmImages });
      } else {
        const newImagesInView = state.lmmImages.filter((x) => x.roadId == roadId);
        dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: newImagesInView });
      }
    },
    [dispatch, state.lmmImages]
  );

  // useEffect(() => {
  //   if (state.roadToSelectMilePost) {
  //     // const latLngLiteralArray = (state.roadToSelectMilePost.geometry as unknown as Point).coordinates.map(
  //     //   (coord: any) => new google.maps.LatLng(coord[1], coord[0])
  //     // );
  //     // console.log('isCoordinateOnLineString----', isCoordinateOnLineString(event.latLng, latLngLiteralArray));
  //   }
  // }, [clickedCoordinate]);

  useEffect(() => {
    if (selectedLmmImage && mapIsSet) {
      forceUseImageCoordinateForCentering.current = true;
      if (selectedLmmImage.roadId !== prevSelectedLmmSection.current?.roadId) {
        const road = roadsRef.find((x) => x.id == selectedLmmImage.roadId);
        setSelectedRoad(road);
        toast({
          title: road?.name ? `You are now on ${capitalizeEachWord(road.name)}` : 'Switching to new road',
          status: 'info',
          duration: 3000,
        });
        updateImagesInView(road.id);
        const nextSection = sectionsRef.current.find((x) => x.roadId == selectedLmmImage.roadId);
        if (!nextSection.geometry?.type) {
          nextSection.geometry = convertToGoogleMapsGeometry(nextSection.geometry) as unknown as Geometry;
        }
        prevSelectedLmmSection.current = selectedLmmSection;
        setSelectedLmmSection(nextSection);
        setSelectedSegment(nextSection);
        markersRef.current.forEach((marker) => {
          if (marker.dataset.roadId === nextSection.roadId) {
            marker.map = mapRef.current;
            if (marker.id === selectedLmmImage.id) {
              marker.content = buildLmmImageMarker(selectedLmmImage, selectedLmmImageId);
              marker.map = mapRef.current;
            }
          }
        });
        const allRelatedSections = sectionsRef.current.filter((x) => x.roadId == nextSection.roadId);
        const featuresToRemove: google.maps.Data.Feature[] = [];
        for (const section of allRelatedSections) {
          const feature = mapRef.current.data.getFeatureById(section.id);
          featuresToRemove.push(feature);
          mapRef.current.data.remove(feature);
        }
        for (const feature of featuresRemovedRef.current) {
          mapRef.current.data.add(feature);
        }
        featuresRemovedRef.current = featuresToRemove;
      } else {
        markersRef.current.forEach((marker) => {
          if (marker.id === selectedLmmImage.id) {
            marker.content = buildLmmImageMarker(selectedLmmImage, selectedLmmImageId);
            marker.map = mapRef.current;
          }
        });
      }
      recenter(
        new google.maps.LatLng(
          selectedLmmImage.position.coordinates[0],
          selectedLmmImage.position.coordinates[1] + 0.0007 //move to left
        ),
        19
      );
    }
    if (previouslySelectedLmmImageRef.current) {
      revertMarkerView();
    }

    previouslySelectedLmmImageRef.current = selectedLmmImage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLmmImage, mapIsSet, selectedLmmImageId, recenter, toast, updateImagesInView, rerun]);

  useEffect(() => {
    if (state.isCreatingJob && selectedLmmImageId && state.startEndMilePost.start) {
      blurImagePointsNotWithin(
        state.startEndMilePost.start,
        state.startEndMilePost.end,
        state.startEndMilePost.polyPath
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLmmImageId, state.isCreatingJob]);

  useEffect(() => {
    if (selectedRoadAsset) {
      if (selectedRoadAsset.assetType == AssetType.inlet || selectedRoadAsset.assetType == AssetType.roadSign) {
        roadAssetMakersRef.current.forEach((marker) => {
          if (marker.id === selectedRoadAsset.id) {
            marker.content =
              marker.dataset.type == AssetType.roadSign
                ? buildStopSignMarker(selectedRoadAsset, selectedRoadAsset.id)
                : buildInletMarker(selectedRoadAsset, selectedRoadAsset.id);
            marker.map = mapRef.current;
          }
        });
        recenter(
          new google.maps.LatLng(
            selectedRoadAsset.geometry.coordinates[0],
            selectedRoadAsset.geometry.coordinates[1] + 0.0007 //move to left
          ),
          19
        );
      }

      previouslySelectedLmmRoadAssetRef.current = selectedRoadAsset;
    }
  }, [recenter, selectedRoadAsset, rerun]);

  // useEffect(() => {
  //   if (
  //     !selectedLmmSection ||
  //     preloadedSegmentImages.current[selectedLmmSection.roadId] ||
  //     preloadedSegmentImages.current[selectedLmmSection.id]
  //   ) {
  //     return;
  //   }
  //   for (const lmmImage of state.lmmImages) {
  //     if (!isSegmentImage(lmmImage, selectedLmmSection)) {
  //       continue;
  //     }
  //     const img = new Image();
  //     img.src = lmmImage.path;
  //     img.onload = function () {
  //       // Warning! this function + log seems to be required (image has to be referenced) - please don't touch.
  //       console.log('** image preloaded', this);
  //     };
  //   }
  //   updateImagesInView(selectedLmmSection.roadId);
  // }, [selectedLmmSection, state.lmmImages, updateImagesInView, rerun]);

  useEffect(() => {
    if (mapIsSet) {
      mapRef.current.data.setStyle((feature) => {
        const id = feature.getId();
        const section = buildSection(feature);
        const strokeColor = getColorFromPci(section.average);
        const strokeOpacity = !selectedLmmSection || selectedLmmSection?.id === id ? 1 : 0.3;
        return {
          strokeColor,
          strokeOpacity,
          strokeWeight: 8,
        };
      });

      if (prevSelectedLmmSection.current) {
        markersRef.current.forEach((marker) => {
          if (isSegmentMarker(marker, prevSelectedLmmSection.current)) {
            marker.map = undefined;
          }
        });
      }
      if (selectedLmmSection && !forceUseImageCoordinateForCentering.current) {
        const markers = getSegmentMarkers(markersRef.current, selectedLmmSection);
        recenter(markers, 19);
      }

      prevSelectedLmmSection.current = selectedLmmSection;

      setSelectedSegment(selectedLmmSection);
      setSectionIsSelected(!!selectedLmmSection);
    }
  }, [mapIsSet, recenter, selectedLmmSection, rerun]);

  // const { refetch: refetchImages } = useQuery<LmmImage[]>(`/api/images?${lmmImagesFilterStr}`, {
  //   refetchOnWindowFocus: false,
  //   refetchOnReconnect: false,
  //   refetchOnMount: true,
  //   async onSuccess(newLmmImages) {
  //     dispatch({ type: DISPATCH_ACTIONS.SET_LMMIMAGES, payload: newLmmImages });
  //     if (!selectedLmmSection) {
  //       dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: newLmmImages });
  //     }
  //     if (lmmImagesFilter.pci[0] == 0 && lmmImagesFilter.pci[1] == 100 && newLmmImages) {
  //       localStorage.setItem('totalImageCount', `${newLmmImages.length}`);
  //     }
  //     const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
  //     const allMarkers = newLmmImages.map((img) => {
  //       const marker = new AdvancedMarkerElement({
  //         position: {
  //           lat: img.position.coordinates[0],
  //           lng: img.position.coordinates[1],
  //         },

  //         content: buildLmmImageMarker(img, selectedLmmImageId),
  //       });

  //       marker.id = img.id;
  //       marker.dataset.roadId = img.roadId;
  //       marker.dataset.sectionId = img.sectionId;
  //       marker.dataset.pci = `${img.pci}`;
  //       marker.addListener('click', () => {
  //         // if (!state.isCreatingJob) {
  //         setSelectedLmmImageId(img.id);
  //         const road = roadsRef.find((x) => x.id == img.roadId);
  //         setSelectedRoad(road);
  //         // }
  //       });

  //       return marker;
  //     });

  //     autoCenter(allMarkers);
  //     if (!selectedLmmImageId) {
  //       markersRef.current = allMarkers;
  //     }
  //   },
  // });

  useEffect(() => {
    if (!state.isCreatingJob && selectedRoad) {
      revertViewForMilePostSelection();
      const roadImages = state.lmmImages.filter((x) => x.roadId == selectedRoad.id);
      markersRef.current.forEach((marker) => {
        const isRoadImage = roadImages.find((x) => x.id == marker.id);
        if (isRoadImage) {
          marker.content = buildLmmImageMarker(isRoadImage, selectedLmmImageId, 1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isCreatingJob]);

  useEffect(() => {
    if ((showFilter.guardrail && showFilter.roadSign, showFilter.sidewalk)) {
      setAssetsFilterisActive(false);
    } else {
      setAssetsFilterisActive(true);
    }
  }, [showFilter]);

  /**
   * Helper function to create marker content with custom text and color.
   */
  const createMarkerContent = (text: string, color: string) => {
    const markerDiv = document.createElement('div');
    markerDiv.style.backgroundColor = color;
    markerDiv.style.borderRadius = '50%';
    markerDiv.style.width = '25px';
    markerDiv.style.height = '25px';
    markerDiv.style.display = 'flex';
    markerDiv.style.justifyContent = 'center';
    markerDiv.style.alignItems = 'center';
    markerDiv.style.color = 'white';
    markerDiv.style.fontWeight = 'bold';
    markerDiv.style.fontSize = '8px';
    markerDiv.innerText = text;
    return markerDiv;
  };

  const loadRoadMilePost = (road: LmmRoad) => {
    const roadImages = state.lmmImages.filter((x) => x.roadId == state.roadToSelectMilePost.id);
    const roadCords = roadImages.map(
      (x) => new google.maps.LatLng(x.position.coordinates[0], x.position.coordinates[1])
    );

    const latLngLiteralArray = reorderLatLngByPath(roadCords);

    const latLngLiteralArrayLength = latLngLiteralArray.length;

    const coordinates = extractCoordinates(latLngLiteralArray);

    dispatch({
      type: DISPATCH_ACTIONS.SET_MILEPOST,
      payload: { start: latLngLiteralArray[0], end: latLngLiteralArray[latLngLiteralArrayLength - 1] },
    });
    const startMarker = new google.maps.marker.AdvancedMarkerElement({
      position: latLngLiteralArray[0],
      map: mapRef.current,
      gmpDraggable: true,
      content: createMarkerContent('Start', APP_COLORS.green), // Green Start marker
    });
    startMarkerRef.current = startMarker;

    // Create AdvancedMarkerElement for the  points
    const endMarker = new google.maps.marker.AdvancedMarkerElement({
      position: latLngLiteralArray[latLngLiteralArrayLength - 1],
      map: mapRef.current,
      gmpDraggable: true,
      content: createMarkerContent('Stop', APP_COLORS.red), // Red Stop marker
    });
    stopMarkerRef.current = endMarker;

    // Add event listeners
    startMarker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      const snappedPosition = getClosestPointOnPath(event.latLng, latLngLiteralArray);
      startMarker.position = snappedPosition;
      dispatch({ type: DISPATCH_ACTIONS.SET_MILEPOST, payload: { start: snappedPosition } });
      blurImagePointsNotWithin(startMarker.position, endMarker.position as google.maps.LatLng, latLngLiteralArray);
    });

    endMarker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      const snappedPosition = getClosestPointOnPath(event.latLng, latLngLiteralArray);
      endMarker.position = snappedPosition;
      dispatch({ type: DISPATCH_ACTIONS.SET_MILEPOST, payload: { end: snappedPosition } });
      blurImagePointsNotWithin(startMarker.position as google.maps.LatLng, endMarker.position, latLngLiteralArray);
    });

    recenter(coordinates, 18);
  };

  const loadViewForMilePostSelection = (road: LmmRoad) => {
    // clear(true);
    hideRoadSigns();
    loadRoadMilePost(road);
  };

  const revertViewForMilePostSelection = () => {
    showRoadSigns();
    if (lmmRoadsRef.current) {
      if (startMarkerRef.current) {
        startMarkerRef.current.map = undefined;
      }
      if (stopMarkerRef.current) {
        stopMarkerRef.current.map = undefined;
        // stopMarkerRef.current = null;
      }
      markersRef.current.forEach((marker) => {
        const pointImage = state.lmmImages.find((x) => x.id == marker.id);
        if (pointImage && marker.content) {
          marker.content = buildLmmImageMarker(pointImage, selectedLmmImageId, 1);
        }
      });
    }
  };

  const blurImagePointsNotWithin = (
    start: google.maps.LatLng,
    end: google.maps.LatLng,
    polylinePath: google.maps.LatLng[]
  ) => {
    if (state.roadToSelectMilePost) {
      const roadImages = state.lmmImages.filter((x) => x.roadId == state.roadToSelectMilePost.id);
      const imagesToBlur: LmmImage[] = [];
      for (const image of roadImages) {
        const blurImage = !isBetweenTwoPointsAlongAPath(
          new google.maps.LatLng(image.position.coordinates[0], image.position.coordinates[1]),
          start,
          end,
          polylinePath
        );
        if (blurImage) {
          imagesToBlur.push(image);
        }
      }
      markersRef.current.forEach((marker) => {
        const isExist = imagesToBlur.find((x) => x.id == marker.id);
        if (isExist) {
          marker.content = buildLmmImageMarker(isExist, selectedLmmImageId, 0.3);
        } else {
          const isRoadImage = roadImages.find((x) => x.id == marker.id);
          if (isRoadImage) {
            marker.content = buildLmmImageMarker(isRoadImage, selectedLmmImageId, 1);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (state.roadToSelectMilePost) {
      loadViewForMilePostSelection(state.roadToSelectMilePost);
    } else {
      revertViewForMilePostSelection();
      // setReRun(!rerun);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.roadToSelectMilePost]);

  const { refetch: refetchRoadSigns } = useQuery<LmmRoadAsset[]>('/api/roadassets', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(newLmmRoadAssets) {
      const guardRails = newLmmRoadAssets.filter((x) => x.assetType == AssetType.guardrail);
      const showAssetCopy = { ...showAssets };
      showAssetCopy.guardrails = guardRails.length > 0;
      const guardRailsCords = guardRails.map((x) => x.geometry);
      const roadSigns = newLmmRoadAssets.filter((x) => x.assetType == AssetType.roadSign);
      const sidewalks = newLmmRoadAssets.filter((x) => x.assetType == AssetType.sidewalk);
      const inlets = newLmmRoadAssets.filter((x) => x.assetType == AssetType.inlet);
      const sidewalksCords = sidewalks.map((x) => x.geometry);
      showAssetCopy.roadSigns = roadSigns.length > 0;
      showAssetCopy.sidewalks = sidewalks.length > 0;
      showAssetCopy.inlets = inlets.length > 0;

      setShowAssets(showAssetCopy);
      guardrailsRef.current = guardRails;
      roadSignsRef.current = roadSigns;
      inletsRef.current = inlets;
      sidewalksRef.current = sidewalks;
      addFeatureGroup(
        mapRef.current,
        guardRails,
        guardRailsCords,
        AssetType.guardrail,
        ['#F4A460', '#000000'],
        setDisplayedFeatures,
        onClickAssetlineCallback
      );
      addFeatureGroup(
        mapRef.current,
        sidewalks,
        sidewalksCords,
        AssetType.sidewalk,
        ['#3888FF', '#ffffff'],
        setDisplayedFeatures,
        onClickAssetlineCallback
      );

      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
      const allMarkers = [...roadSigns, ...inlets].map((asset) => {
        const marker = new AdvancedMarkerElement({
          position: {
            lat: asset.geometry.coordinates[0],
            lng: asset.geometry.coordinates[1],
          },
          map: mapRef.current,
          content: asset.assetType == AssetType.roadSign ? buildStopSignMarker(asset) : buildInletMarker(asset),
        });
        marker.addListener('click', () => {
          // if (!createJobOpenedRef.current) {
          // setSelectedLmmImageId(img.id);
          // const road = roadsRef.find((x) => x.id == img.roadId);
          // setSelectedRoad(road);
          setSelectedRoadAsset(asset);
          // }
        });
        marker.id = asset.id;
        marker.dataset.type = asset.assetType;
        return marker;
      });

      roadAssetMakersRef.current = allMarkers;

      // Initialize MarkerClusterer and pass the map and markers array to it
      clustererRef.current = new MarkerClusterer({
        markers: allMarkers,
        map: mapRef.current, // Reference to your map
        renderer: new CustomRenderer(),
      });
    },
  });

  const onClickAssetlineCallback = (asset: LmmRoadAsset) => {
    setSelectedRoadAsset(asset);
    if (asset.assetType == AssetType.sidewalk || asset.assetType == AssetType.guardrail) {
      const data = asset.assetType == AssetType.sidewalk ? sidewalksRef.current : guardrailsRef.current;
      const assetsOnRoad = data.filter((x) => x.assetType == asset.assetType && x.roadName == asset.roadName);
      for (const relatedAsset of assetsOnRoad) {
        const img = new Image();
        img.src = relatedAsset.path;
        img.onload = function () {
          // Warning! this function + log seems to be required (image has to be referenced) - please don't touch.
          console.log('** asset image preloaded', this);
        };
      }
    }
  };

  const showRoadSigns = async (assetType?: AssetType) => {
    const toAdd = assetType
      ? roadAssetMakersRef.current.filter((x) => x.dataset.type == assetType)
      : roadAssetMakersRef.current;
    if (clustererRef.current && mapRef.current) {
      clustererRef.current.addMarkers(toAdd); // Re-add markers to the cluster
    }
  };

  const hideRoadSigns = async (assetType?: AssetType): Promise<void> => {
    if (!clustererRef.current) {
      console.error('Clusterer is not initialized.');
      return;
    }

    if (!assetType) {
      clustererRef.current.clearMarkers();
    } else {
      const markersToRemove = roadAssetMakersRef.current.filter((marker) => marker.dataset.type === assetType);

      // console.log('markersToRemove', markersToRemove.length);

      const batchSize = 50;
      let index = 0;
      const isOtherVisible = assetType == AssetType.inlet ? showFilter.roadSign : showFilter.inlet;
      const removeBatch = () => {
        if (index >= markersToRemove.length) {
          // console.log('All markers removed');
          clustererRef.current.clearMarkers();
          if (isOtherVisible) {
            clustererRef.current.addMarkers(
              roadAssetMakersRef.current.filter((marker) => marker.dataset.type !== assetType)
            );
          }

          return;
        }

        const batch = markersToRemove.slice(index, index + batchSize);
        batch.forEach((marker) => (marker.map = null));

        index += batchSize;
        setTimeout(removeBatch, 50); // Add delay to prevent UI freeze
      };

      removeBatch();
    }
  };
  useEffect(() => {
    setIsLoading(true);
  }, []);

  const { refetch: refetchLmmSections } = useQuery<LmmSection[]>(`/api/sections?${lmmImagesFilterStr}`, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess(sections) {
      sectionsRef.current = sections;
      dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: state.lmmImages });
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMSECTIONS, payload: sections });
      clear();
      const features = buildFeatures(sections);
      mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });
      autoCenter(mapRef.current.data);
    },
  });

  const onToggleToSectionView = () => {
    closeLmmRoadModal();
    setSelectedLmmSection(null);
    clear();
    const features = buildFeatures(sectionsRef.current);
    mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });
    updateImagesInView();
    if (lmmImagesFilter.pci != defaultPciRange) {
      setLmmImagesFilter({ pci: defaultPciRange });
      setAssetsFilterisActive(false);
    }
    if (startMarkerRef.current) {
      startMarkerRef.current.map = undefined;
    }
    if (stopMarkerRef.current) {
      stopMarkerRef.current.map = undefined;
    }
    dispatch({
      type: DISPATCH_ACTIONS.SET_MILEPOST,
      payload: { start: null, end: null },
    });
  };

  const loopLmmImage = (direction: 1 | -1) => {
    const index = state.lmmImages.findIndex(({ id }) => id === selectedLmmImage.id);
    const newIndex = (index + direction + state.lmmImages.length) % state.lmmImages.length;
    setSelectedLmmImageId(state.lmmImages[newIndex].id);
  };

  const loopRoadAssetImage = (direction: 1 | -1, assetType: AssetType) => {
    const data = assetType == AssetType.sidewalk ? sidewalksRef.current : guardrailsRef.current;
    const assetsOnRoad = data.filter((x) => x.assetType == assetType && x.roadName == selectedRoadAsset.roadName);
    const index = assetsOnRoad.findIndex(({ id }) => id === selectedRoadAsset.id);
    const newIndex = (index + direction + assetsOnRoad.length) % assetsOnRoad.length;
    if (index == 0 && direction == -1) {
      toast({
        title: 'start of the ' + assetType,
        status: 'info',
        duration: 2500,
      });
      return;
    }
    if (index == assetsOnRoad.length - 1 && direction == 1) {
      toast({
        title: 'You are already at the end of the ' + assetType,
        status: 'info',
        duration: 2500,
      });
      return;
    }
    setSelectedRoadAsset(assetsOnRoad[newIndex]);
  };

  const closeLmmImageModal = () => {
    revertMarkerView(true);
    setSelectedLmmImageId(undefined);
  };

  const closeLmmRoadAssetModal = () => {
    revertRoadAssetMarkerView();
    setSelectedRoadAsset(undefined);
  };

  const closeLmmRoadModal = () => {
    setSelectedRoad(undefined);
  };

  const clearLmmImages = () => {
    markersRef.current.forEach((marker) => {
      if (marker.dataset.type !== 'roadSign') {
        marker.map = undefined;
      }
    });
  };

  const clearLmmSections = (isPartial?: boolean) => {
    mapRef.current.data.forEach((feature) => mapRef.current.data.remove(feature));
    if (isPartial) {
      setSelectedLmmSection(undefined);
    }
  };

  const clear = (isPartial?: boolean) => {
    clearLmmImages();
    clearLmmSections(isPartial);
    setSectionIsSelected(isPartial || false);
  };

  const onSaveImage = (image: LmmImage) => {
    revertMarkerView(true);
    // TODO: Reftch image
    // refetchImages();
  };

  const onFilterMap = (pci: [number, number]) => {
    if (selectedLmmSection) {
      // clear();
      if (pci[0] != 0 || pci[1] != 100) {
        console.log('pci', pci);
      }
      //TODO:
      // const features = buildFeatures(state.lmmSections);
      // mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });

      const imageInView: LmmImage[] = [];
      markersRef.current.forEach((marker) => {
        if (marker.dataset.roadId != selectedLmmSection.roadId) {
          return;
        }
        const imagePci = Number(marker.dataset.pci);
        if ((imagePci >= pci[0] && imagePci <= pci[1]) || (Number.isNaN(imagePci) && pci[0] == 0 && pci[1] == 100)) {
          const image = { id: marker.id, pci: imagePci };
          marker.content = buildLmmImageMarker(image);
          marker.map = mapRef.current;
          if (!Number.isNaN(imagePci)) {
            imageInView.push(image);
          }
        } else {
          marker.map = undefined;
        }
      });
      dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: imageInView });
    } else {
      // TODO:
      // console.log('NO selectedLmmSection');
      // clear()
      // setLmmImagesFilter({ ...lmmImagesFilter, pci });
      const filterdSections = state.lmmSections.filter((x) => x.average >= pci[0] && x.average <= pci[1]);
      // dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: state.lmmImages });
      clear();
      const features = buildFeatures(filterdSections);
      mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });
      // autoCenter(mapRef.current.data);
      // const lmmImages = [...state.lmmImages];
    }
  };

  // const adjustIconSize = () => {
  //   const zoomLevel = window.devicePixelRatio;
  //   const elements = document.querySelectorAll('.signMarker');
  //   console.log('zoomLevel', zoomLevel);
  //   elements.forEach((element) => {
  //     element.style.transform = `scale(${zoomLevel})`; // Example transformation
  //   });
  //   // icon. = `scale(${zoomLevel})`;
  // };
  // window.addEventListener('resize', adjustIconSize);

  // adjustIconSize();

  return (
    <>
      {isLoading && <FullScreenLoader loaderText="Loading Data..." />}
      <TopNavBar mapRef={mapRef} markersRef={markersRef} segments={segments} screen="Map" />
      <div ref={mapElRef} style={mapStyle} className="map">
        <MapToolbar
          lmmImagesFilter={lmmImagesFilter}
          setLmmImagesFilter={setLmmImagesFilter}
          onFilterMap={onFilterMap}
          recenter={recenter}
          deselectLmmSection={onToggleToSectionView}
          showDeselectSection={sectionIsSelected}
          loadingSection={isLoadingSections}
          toggleRoadAssetView={onToggleShowRoadAsset}
          assetFilterisActive={assetFilterisActive}
          showFilter={showFilter}
          action={endAnction}
          setAction={setEndAction}
          showAssets={showAssets}
        />
        {selectedLmmImage && (
          <MapLmmImageModal
            lmmImage={selectedLmmImage}
            loop={loopLmmImage}
            onSave={onSaveImage}
            onClose={closeLmmImageModal}
            roadName={selectedRoad?.name}
          />
        )}
        {selectedRoadAsset && (
          <MapRoadAssetModal
            lmmRoadAsset={selectedRoadAsset}
            loop={loopRoadAssetImage}
            onClose={closeLmmRoadAssetModal}
          />
        )}
        {selectedRoad && (
          <RoadInfoModal onClose={closeLmmRoadModal} road={selectedRoad} lmmImages={state.imagesInView} />
        )}
      </div>
    </>
  );
}

const mapStyle = {
  width: '100vw',
  height: '100vh',
} satisfies CSSProperties;
